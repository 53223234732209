:root {
    --violet-dark: #282071;
    --violet-bright: #5850EC;
    --violet-bg: #f1f5f9;
    --violet-scrollbar: #e3e7eb;
    --green-bright:#48BB78;
    --red-bright:#E21E1E;
    --grey: #82868F;
    --info: #4786FF;
    --info-bg: #E1EFFF;
}

p {
    margin-bottom: 2px
}

.ant-menu-submenu-popup.ant-menu-submenu {
    background-color: #3f2571;
}

.ant-pagination .ant-pagination-item {
    border: none
}

.ant-btn-dashed {
    padding: 0;
	font-size: 13px;
	font-weight: 500;
	color: var(--violet-dark);
    background-color: rgba(0, 0, 0, 0);
	border: 1px solid var(--violet-dark);
	border-style: none none solid none;
	border-radius: 0;
}

.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover {
    color: var(--violet-bright);
}

/* .ant-input, .ant-select {
    border: 1px solid #E6E7E9;
    padding: 15px;
    font-size: 16px;
    color: #57606A;
} */


* {
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: var(--violet-bg);
    }

    ::-webkit-scrollbar-thumb {
        background: var(--violet-scrollbar);
        border-radius: 8px;
    }
}